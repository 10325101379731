import Text from "@atoms/Text";
import texts from '@components/Homepage/en.json';
import { RegulatedFrameworkData } from "@helpers/homepageData";
import Image from "@atoms/Image";

const RegulatedFramework = () => {
    return (
        <div className="relative max-width-wrapper py-12 px-4 md:py-16 xl:py-18 md:px-6 xl:px-16">
            <div className="absolute z-0 top-[700px] -right-36 md:top-0 md:-right-56 xl:-right-[350px] w-[200%] md:w-[160%] xl:w-[140%]">
                <Image src='/images/CreditEvaluationProcess/jiraaf-light-gray-bg-right.png' className="w-full -rotate-45 md:-rotate-12 xl:-rotate-6" loading='lazy' />
            </div>
            <Text content={texts?.RegulatedFramework} className="z-10 text-center h4-semibold md:h3-semibold xl:h2-semibold text-primary-500" htmlTag="h2" />
            <div className="relative z-10 mt-6 md:mt-8 flex flex-col gap-y-4 md:gap-y-6" >
                {RegulatedFrameworkData?.map((item, index) => (
                    <div key={index} className="relative flex flex-col p-6 border-1 border-periwinkle-100 rounded-xl bg-basicWhite/70" data-aos="fade-up" data-aos-offset="0" >
                        <Text content={item.title} className="h5-semibold md:h4-semibold text-primary-500" htmlTag="h3" />
                        <Text content={item.description} className="mt-2 p5-regular md:p4-regular text-gray-600" />
                        {item?.images && <div className="flex flex-wrap mt-4 justify-start gap-x-6 md:gap-x-12 gap-y-1 md:gap-y-2">
                            {item.images?.map((logo, index) => (
                                <div key={index} className="rounded-md flex justify-center items-center">
                                    <Image src={logo.url} className='h-[64px]' alt={logo.altName} loading='lazy' />
                                </div>
                            ))}
                        </div>}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RegulatedFramework;